import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";

import {Box, Button, TextField, Typography} from "@mui/material";

import {login} from "../../../services/auth/auth";
import {routes} from "../../../config/routes";

import AuthLayout from "../AuthLayout";

const Login = ({isTenant = false}: { isTenant?: boolean }) => {
    const {t} = useTranslation();

    const navigate = useNavigate();

    const {control, handleSubmit, getValues} = useForm<{
        email: string;
        password: string;
    }>({
        defaultValues: {
            email: "",
            password: "",
        },
        mode: "onChange",
    });

    const handleSignUp = () => {
        handleSubmit(async () => {
            // try to sign in
            const {error} = await login(getValues("email"), getValues("password"));

            if (error) {
                alert(error);
            }
            //   signed in
            else {
                navigate(`${routes.index}`);
            }
        })();
    };

    // const handleGoToSignIn = () => {
    //   navigate(routes.signIn);
    // };

    return (
        <AuthLayout>
            <Box sx={{"& >*": {marginY: 1}}}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                        justifyContent: "center",
                        "& >*": {marginY: 1.5},
                    }}
                >
                    <Typography variant="h3">{t("auth.welcome")}</Typography>

                    {/* link to register */}
                    {/* TODO: activate when launching online */}
                    {!isTenant && (
                        <Typography>
                            {t('auth.subtitle')}{" "}
                            <a href={routes.signIn}>
                                <b>{t('auth.registerHere')}</b>
                            </a>
                        </Typography>
                    )}

                    {/* text for tenant */}
                    {isTenant && (
                        <Typography>
                            Obtén beneficios solo por ser un buen arrendatario
                        </Typography>
                    )}
                </Box>
                <Controller
                    name="email"
                    rules={{required: {message: "It's required", value: true}}}
                    control={control}
                    render={({
                                 field: {value, onChange, name},
                                 formState: {errors},
                             }) => (
                        <>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Email"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                            />
                            <Typography variant="body2" color="error.main">
                                {errors[name]?.message}
                            </Typography>
                        </>
                    )}
                />

                <Controller
                    name="password"
                    rules={{required: {message: "It's required", value: true}}}
                    control={control}
                    render={({
                                 field: {value, onChange, name},
                                 formState: {errors},
                             }) => (
                        <>
                            <TextField
                                fullWidth
                                margin="normal"
                                label={t("auth.password")}
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                type="password"
                            />
                            <Typography variant="body2" color="error.main">
                                {errors[name]?.message}
                            </Typography>
                        </>
                    )}
                />

                {/* <Typography
          variant="body1"
          style={{ fontSize: "11px", marginTop: 10, marginBottom: 20 }}
        >
          Si olvidaste tu contraseña, envianos un email a nido@yournido.com
        </Typography> */}

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                    }}
                >
                    <Button
                        onClick={handleSignUp}
                        variant="contained"
                        sx={{marginBottom: 2}}
                        size="large"
                    >
                        {t("auth.signin")}
                    </Button>

                    {/* <Button onClick={handleGoToSignIn} variant="outlined" size="small">
            I do not have an account
          </Button> */}
                </Box>
            </Box>
        </AuthLayout>
    );
};

export default Login;
