import {useTranslation} from "react-i18next";
import {useContext} from "react";
import {useNavigate} from "react-router-dom";

import {
    Box,
    Drawer,
    alpha,
    styled,
    Divider,
    useTheme,
    lighten,
    darken,
    Typography,
    ListItem,
    Button,
    List,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Scrollbar from "components/Scrollbar";
import {SidebarContext} from "contexts/SidebarContext";
import SidebarMenu from "./SidebarMenu";

import {routes} from "config/routes";
import {logout} from "services/auth/auth";

import logo from "static/logoWhite.png";
// import LanguageSelector from "../../../components/languageSelector";
// import HelpButton from "../../../components/helpButton";

// TODO: move this to toher style file
const SidebarWrapper = styled(Box)(
    ({theme}) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 68px;
`
);

function Sidebar() {
    const {sidebarToggle, toggleSidebar} = useContext(SidebarContext);
    const closeSidebar = () => toggleSidebar();
    const theme = useTheme();

    const navigate = useNavigate();

    const handleLogout = async () => {
        await logout();
        navigate(routes.login);
    };

    const {t} = useTranslation();

    return (
        <>
            <SidebarWrapper
                sx={{
                    display: {
                        xs: "none",
                        lg: "inline-block",
                    },
                    position: "fixed",
                    left: 0,
                    top: 0,
                    background:
                        theme.palette.mode === "dark"
                            ? alpha(lighten(theme.header.background, 0.1), 0.5)
                            : darken(theme.colors.alpha.black[100], 0.5),
                    boxShadow:
                        theme.palette.mode === "dark" ? theme.sidebar.boxShadow : "none",
                }}
            >
                <Scrollbar>
                    <Box>
                        <Box
                            sx={{
                                marginLeft: 4,
                                backgroundColor: '#5c48f7',
                                width: '120px',
                                padding: 0.5,
                                borderRadius: 0.5,
                                textAlign: 'center',
                                marginBottom: 3,
                                marginTop: 3
                                // fontWeight: 'bold'
                            }}
                        >
                            {/*<img src={logo} alt="Logo" width={150} height={10}/>*/}
                            <Typography style={{fontWeight: 'bold', color: 'white'}}>
                                Nidoscout.com
                            </Typography>
                        </Box>
                    </Box>
                    <Divider
                        sx={{
                            // mt: theme.spacing(3),
                            mx: theme.spacing(2),
                            background: theme.colors.alpha.trueWhite[10],
                        }}
                    />
                    <SidebarMenu/>
                    <Divider
                        sx={{
                            // mt: theme.spacing(3),
                            mx: theme.spacing(2),
                            background: theme.colors.alpha.trueWhite[10],
                        }}
                    />
                    {/*<Box style={{*/}
                    {/*    marginTop: '30px',*/}
                    {/*    // backgroundColor: 'red'*/}
                    {/*}}>*/}
                    {/*    <LanguageSelector/>*/}
                    {/*    /!*<HelpButton/>*!/*/}
                    {/*</Box>*/}
                </Scrollbar>
                <Divider
                    sx={{
                        background: theme.colors.alpha.trueWhite[10],
                    }}
                />
                {/* logout */}
                <List>
                    <ListItem component="div">
                        <Button
                            disableRipple
                            onClick={handleLogout}
                            startIcon={<CloseIcon/>}
                            variant="text"
                            sx={{color: "white"}}
                        >
                            {t("general.logout")}
                        </Button>
                    </ListItem>
                </List>
            </SidebarWrapper>
            <Drawer
                sx={{
                    boxShadow: `${theme.sidebar.boxShadow}`,
                }}
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={sidebarToggle}
                onClose={closeSidebar}
                variant="temporary"
                elevation={9}
            >
                <SidebarWrapper
                    sx={{
                        background:
                            theme.palette.mode === "dark"
                                ? theme.colors.alpha.white[100]
                                : darken(theme.colors.alpha.black[100], 0.5),
                    }}
                >
                    <Scrollbar>
                        <Box mt={3}>
                            <Box
                                mx={2}
                                sx={{
                                    width: 52,
                                }}
                            >
                                <Typography variant="h3" color={"white"}>
                                    Nidô
                                </Typography>
                            </Box>
                        </Box>
                        <Divider
                            sx={{
                                mt: theme.spacing(3),
                                mx: theme.spacing(2),
                                background: theme.colors.alpha.trueWhite[10],
                            }}
                        />
                        <SidebarMenu/>
                    </Scrollbar>
                </SidebarWrapper>
            </Drawer>
        </>
    );
}

export default Sidebar;
