import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {supabase} from "../../../config/supabaseClient";
import Loader from "../../../components/Loader";
import {Alert, Button, Card, CardContent, CardHeader, Container, Typography} from "@mui/material";
import {PropertySearched} from "../../../types/entities/propertiesSearched";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Box} from "@mui/system";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {Rating} from "@mui/lab";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import InsightsIcon from '@mui/icons-material/Insights';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import BeachAccessOutlinedIcon from '@mui/icons-material/BeachAccessOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';

type PropertyItemReport = {
    item: string,
    value: string
}

const PropertyReport = () => {

    const navigate = useNavigate();

    const [property, setProperty] = useState<PropertySearched | undefined>(undefined); // To store the response from the API
    const [loading, setLoading] = useState(true); // Loading state to show loading indicator

    const {propertyId} = useParams();

    useEffect(() => {
        const fetchReport = async () => {

            let {data: PropertySearched, error} = await supabase
                .from('PropertySearched')
                .select("*")

                // Filters
                .eq('id', propertyId);

            const _property = PropertySearched[0] as PropertySearched;

            // format report
            // const report = _property['report']['items'];
            // const overallReport = _property['report']['overall'];
            // const overallRating = _property['report']['overallRating'];

            // delete report
            // delete _property.report;

            // setProperty({..._property, report, overallReport, overallRating});
            setProperty(_property)
            setLoading(false);

        };

        fetchReport();
    }, [propertyId]); // Fetch data when component mounts, and when 'link' changes

    // Conditional rendering based on the states
    if (loading) {
        return <Loader/>;
    }

    const handleGoBack = () => {
        navigate(-1);  // -1 means going back to the previous page in the history
    };

    const renderItem = (title: string, content: string, rating: number | undefined, Icon: any) => {
        // Convert the 0-10 rating to a 0-5 scale
        const scaledRating = rating / 2;

        return (
            <Card style={{marginBottom: 50, padding: 15}}>
                <CardContent>
                    <Box style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between', // Spread the title and rating across the row
                        marginBottom: 10
                    }}>
                        <Box style={{display: 'flex', alignItems: 'center'}}>
                            <Icon style={{marginRight: 8}}/>
                            <Typography variant='h3'>
                                {title}
                            </Typography>
                        </Box>

                        {/* Box to display the rating */}
                        {rating &&
                            <Rating
                                name="simple-controlled"
                                value={scaledRating}
                                // onChange={(event, newValue) => {
                                //     // setValue(newValue);
                                // }}
                                max={5}
                                precision={0.1}
                                readOnly
                            />
                        }
                    </Box>
                    <Typography style={{
                        wordWrap: 'break-word',  // Ensures long words wrap to the next line
                        whiteSpace: 'pre-wrap',  // Preserves the new lines, tabs, etc.
                    }}>
                        {content}
                    </Typography>
                </CardContent>
            </Card>
        )
    };

    const itemIcons = {
        'Summary': InfoOutlinedIcon,
        'Price analysis': SellOutlinedIcon,
        'Location': LocationOnOutlinedIcon,
        'Rental income potential': MapsHomeWorkOutlinedIcon,
        // TODO: change icon
        'Short-term rental income potential': BeachAccessOutlinedIcon,
        'Price increase potential': TrendingUpOutlinedIcon,
        'Operating costs': InsertChartOutlinedIcon,
        // TODO: change icon
        'Additional costs breakdown': CardTravelIcon,
        'ROI': InsightsIcon
    };

    return (
        <Container>

            {/*user message */}
            <Alert
                // icon={<CheckIcon fontSize="inherit"/>}
                severity="success"
                style={{marginBottom: 10}}
            >
                We’re just getting started on our software journey, and we're committed to continuously enhancing our
                property reports with more insights and valuable data to support your decision-making. Please note that
                the calculations provided are estimates and may vary from actual figures.
            </Alert>

            {/*back button*/}
            <Button
                style={{marginBottom: 40}}
                onClick={handleGoBack}
                variant='outlined'
            >
                <ArrowBackIcon/>
            </Button>

            {/*<Typography variant='h3'>Property Report</Typography>*/}
            <Box style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 30
            }}>
                <Typography variant='h2'>
                    {property.propertyInfo.title}
                </Typography>
                <Button onClick={() => window.open(property.link)} variant='outlined'>
                    <OpenInNewIcon
                        style={{marginRight: 10, cursor: 'pointer'}}
                    />
                    View the listing
                </Button>
            </Box>

            <pre>

                {/* general info property */}
                <Box style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 10
                }}>
                    {/*<Typography>*/}
                    {/*    <b>Property:</b> {property.propertyInfo.title}*/}
                    {/*</Typography>*/}
                    <Box style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: 20
                    }}>
                        <LocationOnOutlinedIcon style={{marginRight: 5}}/>
                        <Typography>
                            {property.propertyInfo.location.replace("Ubicación", "").replace(/\n+/g, ', ').replace(/^, /, '').trim()}
                        </Typography>
                    </Box>
                    <Box style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: 17
                    }}>
                        <SellOutlinedIcon style={{marginRight: 5}}/>
                        <Typography>
                            {property.propertyInfo.price}
                        </Typography>
                    </Box>

                    <Box style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: 30
                    }}>
                        <HomeOutlinedIcon style={{marginRight: 5}}/>
                        <Typography style={{
                            wordWrap: 'break-word',  // Ensures long words wrap to the next line
                            whiteSpace: 'pre-wrap',  // Preserves the new lines, tabs, etc.
                        }}>
                        {property.propertyInfo.infoFeatures.replace(/\s+/g, ' ').replace(/(\d+ m²)/, '$1,').trim()}
                    </Typography>
                    </Box>

                </Box>

                {/* overall report*/}
                <>{renderItem('Summary', property.report.overall, property.report.overallRating, itemIcons['Summary'])}</>

                {/*each item of the report*/}
                {property.report &&
                    property.report.items.map((item) => {
                        return <div
                            key={item.item}>{renderItem(item.item, item.value, item.rating, itemIcons[item.item])}</div>
                    })
                }</pre>
        </Container>
    );
}

export default PropertyReport;