import BaseSearchedProperties from "./components/Base";
import {useAuth} from "hooks/auth";
import {useGetLandlordProfile} from "services/landlord/landlord";
import {useGetUserSearchedProperties} from "services/propertiesSearched";
import {Container, Typography} from "@mui/material";

const UserSearchedProperties = ({limit, title}: { limit?: number, title?: string }) => {

    const {user} = useAuth();

    const {landlord} = useGetLandlordProfile({userAuthId: user?.id})

    const {properties, isLoading} = useGetUserSearchedProperties({
            landlordId: landlord?.id
        })
    ;

    const propertiesToDisplay = (limit !== undefined && properties) ? properties.slice(0, limit) : properties;

    return <Container>
        {propertiesToDisplay && propertiesToDisplay.length > 0 ?
            <>
                <div style={{marginBottom: 35}}>
                    <Typography variant='h3'>
                        {title ? title : "Your searches"}
                    </Typography>
                    {/*<Typography>*/}
                    {/*    Here you can check all the properties you have searched for*/}
                    {/*</Typography>*/}
                </div>
                <BaseSearchedProperties properties={propertiesToDisplay} isLoading={isLoading}/>
            </>
            :
            <>
                <Typography variant={'h4'} gutterBottom>
                    No properties searched yet
                </Typography>
                <Typography>Start searching for any Idealista property to get a full report</Typography>
            </>
        }
    </Container>
}

export default UserSearchedProperties;