import {Bar} from "react-chartjs-2";
import {useGetUserSearchedProperties} from "../../../services/propertiesSearched";
import {useAuth} from "../../../hooks/auth";
import {useGetLandlordProfile} from "../../../services/landlord/landlord";
import {Container, Typography} from "@mui/material";

const ReportsAnalytics = () => {
    const {user} = useAuth();

    const {landlord} = useGetLandlordProfile({
        userAuthId: user?.id
    })

    const {properties} = useGetUserSearchedProperties({landlordId: landlord?.id})

    // Prepare data for the chart
    const data = properties ? {
        labels: properties.map(property => property.propertyInfo.title), // Extract property titles as labels
        datasets: [
            {
                data: properties.map(property => property.report.overallRating / 2), // Extract ratings as data points
                backgroundColor: '#646cec', // Set bar color with some transparency
                borderColor: '#646cec',
                borderWidth: 1,
            }
        ]
    } : undefined;

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                ticks: {
                    autoSkip: false, // Show all labels if needed
                },
            },
            y: {
                beginAtZero: true,
                max: 5, // Assuming the rating is out of 5
            }
        },
        // Set the thickness of the bars directly on the dataset
        plugins: {
            tooltip: {
                enabled: true
            }
        },
        // elements: {
        //     bar: {
        //         // barPercentage: 20, // Adjust this value for the bar width
        //         width: 20,
        //     }
        // }
    };

    return <Container>
        {data &&
            <>
                <Typography variant='h3'>
                    General rating
                </Typography>
                <Bar
                    options={options}
                    data={data}
                    style={{maxHeight: "250px"}}
                />
            </>
        }
    </Container>
}

export default ReportsAnalytics;