import {useNavigate} from "react-router-dom";

import {routes} from "config/routes";

import {PropertySearched} from "types/entities/propertiesSearched";
import Loader from "components/Loader";
import {Box, Button, Card, CardContent, Grid, Typography} from "@mui/material";
import {Rating} from "@mui/lab";

const BaseSearchedProperties = (
    {
        properties,
        isLoading
    }: {
        properties: PropertySearched[],
        isLoading: boolean
    }
) => {
    const navigate = useNavigate();

    const handleClick = (propertyId: number) => navigate(`/${routes.reports.base}/${routes.reports.property}/${propertyId}`);

    if (isLoading) {
        return <>
            <Loader/>
        </>
    }

    return <>
        <Grid container spacing={2}>

            {
                properties && properties.map((property) => {
                    return (
                        // <div onClick={() => handleClick(property.id)}><p>{property.id}</p><p>{property.link}</p></div>
                        <Grid item xs={4} key={property.id}>
                            <Card sx={{minWidth: 275}}>
                                <CardContent>
                                    {/*<Typography gutterBottom sx={{color: 'text.secondary', fontSize: 14}}>*/}
                                    {/*    Oportunity*/}
                                    {/*</Typography>*/}
                                    <Rating value={property['report']['overallRating']} readOnly/>
                                    <Typography variant="h5" component="div"
                                                style={{
                                                    marginTop: 10,
                                                    marginBottom: 30,
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',      /* Prevents text from wrapping to a new line */
                                                    overflow: 'hidden'
                                                }}>
                                        {property.propertyInfo.title}
                                    </Typography>
                                    {/*<Typography sx={{color: 'text.secondary', mb: 1.5}}>adjective</Typography>*/}
                                    {/*<Typography variant="body2">*/}
                                    {/*    well meaning and kindly.*/}
                                    {/*    <br/>*/}
                                    {/*    {'"a benevolent smile"'}*/}
                                    {/*</Typography>*/}
                                    <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <Typography style={{
                                            fontWeight: 'bold',
                                            color: '#646cec'
                                        }}>{property.propertyInfo.price}</Typography>
                                        <Button size="small" onClick={() => handleClick(property.id)}
                                                style={{textDecoration: 'underline'}}>View report</Button>
                                    </Box>
                                </CardContent>
                                {/*<CardActions>*/}
                                {/*</CardActions>*/}
                            </Card>
                        </Grid>
                    )
                })
            }
        </Grid>
    </>
}

export default BaseSearchedProperties;