import {Suspense, lazy} from "react";

import {RouteObject} from "react-router";

import {routes} from "config/routes";

import SuspenseLoader from "components/SuspenseLoader";
import SidebarLayout from "layout/SidebarLayout";
import BaseLayout from "layout/BaseLayout";
import AuthRequired from "components/auth/AuthRequired";
import Analytics from "pages/analytics/Analytics";
import Perks from "pages/perks/Perks";
import Transactions from "pages/transactions";
import ConnectBank from "pages/connectBank/ConnectBank";
import SetupTaxes from "pages/taxes/setup/Setup";
import SignIn from "pages/auth/signIn/SignIn";
import PerksSniptech from "pages/perks/sniptech/PerksSniptech";
import PerksSniptechDashboard from "pages/perks/sniptech/PerksSniptechDashboard";
import LoginTenant from "pages/auth/login/LoginTenant";
import Index from "./pages/index/Index";
import Loans from "./pages/loans";
import ConnectBankForLoans from "./pages/loans/pages/ConnectBankForLoans";
import Congratulations from "./pages/loans/pages/Congratulations";
import NewLoanForm from "./pages/loans/pages/NewLoanForm";
import HomeReports from "./pages/propertyReports/homeSearch/homeSearch";
import PropertyReport from "./pages/propertyReports/report";
import UserSearchedProperties from "./pages/propertyReports/lists/UserSearchedProperties";
import AllProperties from "./pages/propertyReports/lists/AllProperties";
import PaymentConfirmation from "./pages/general/paymentConfirmation";
import Plans from "./pages/general/plans";
import ReportsAnalytics from "./pages/propertyReports/analytics";

const Loader = (Component: any) => (props: any) =>
    (
        <Suspense fallback={<SuspenseLoader/>}>
            <Component {...props} />
        </Suspense>
    );

// pages
// const Index = Loader(lazy(() => import("pages/index/Index")));
const Login = Loader(lazy(() => import("pages/auth/login/Login")));
const HomeProperty = Loader(
    lazy(() => import("pages/properties/home/HomeProperty"))
);
const AddProperty = Loader(lazy(() => import("pages/properties/addProperty")));
const EditProperty = Loader(
    lazy(() => import("pages/properties/editProperty/EditProperty"))
);

const ContractsHome = Loader(
    lazy(() => import("pages/contracts/home/ContractsHome"))
);

const PropertyContracts = Loader(
    lazy(() => import("pages/contracts/propertyContracts/PropertyContracts"))
);

const CreateContract = Loader(
    lazy(() => import("pages/contracts/createContract/CreateContract"))
);

const ContractDetails = Loader(
    lazy(() => import("pages/contracts/contractDetails/ContractDetails"))
);

const GuestPortal = Loader(
    lazy(() => import("pages/contracts/guestPortal/GuestPortal"))
);

const LandLordProfile = Loader(
    lazy(() => import("pages/landlord/profile/LandlordProfile"))
);
const LandlordBankInformation = Loader(
    lazy(() => import("pages/landlord/bankInformation"))
);

const LandlordSignature = Loader(
    lazy(() => import("pages/landlord/signature"))
);

const Status404 = Loader(lazy(() => import("pages/status/Status404")));

// constants
const basePath = "";

// routes
// TODO: add authorization component
const routesComponent: RouteObject[] = [
    // login
    {
        path: routes.login,
        element: <BaseLayout/>,
        children: [
            {
                path: basePath,
                element: <Login/>,
            },
        ],
    },

    // loging tenant
    {
        path: routes.loginTenant,
        element: <BaseLayout/>,
        children: [
            {
                path: basePath,
                element: <LoginTenant/>,
            },
        ],
    },

    // sign in
    {
        path: routes.signIn,
        element: <BaseLayout/>,
        children: [
            {
                path: basePath,
                element: <SignIn/>,
            },
        ],
    },

    {
        path: "",
        element: <AuthRequired/>,
        children: [

            // payment confirmations
            {
                path: routes.paymentConfirmation,
                element: <SidebarLayout/>,
                children: [
                    {
                        path: basePath,
                        element: <PaymentConfirmation/>,
                    },
                ],
            },

            // plans
            {
                path: routes.plans,
                element: <SidebarLayout/>,
                children: [
                    {
                        path: basePath,
                        element: <Plans/>,
                    },
                ],
            },

            // home page
            {
                path: routes.index,
                element: <SidebarLayout/>,
                children: [
                    {
                        path: basePath,
                        // element: <Index />,
                        element: <HomeReports/>,
                    },
                ],
            },

            // analytics
            {
                path: routes.analytics,
                element: <SidebarLayout/>,
                children: [
                    {
                        path: basePath,
                        element: <Analytics/>,
                    },
                ],
            },

            // transactions
            {
                path: routes.transactions,
                element: <SidebarLayout/>,
                children: [
                    {
                        path: basePath,
                        element: <Transactions/>,
                    },
                ],
            },

            // cashback
            {
                path: routes.perks,
                element: <SidebarLayout/>,
                children: [
                    {
                        path: basePath,
                        element: <Perks/>,
                    },
                ],
            },

            // sniptech cashabck
            {
                path: routes.perksSniptech,
                element: <SidebarLayout/>,
                children: [
                    {
                        path: basePath,
                        element: <PerksSniptech/>,
                    },
                ],
            },

            // sniptech dashboard cashback
            {
                path: routes.perksSniptechDashboard,
                element: <SidebarLayout/>,
                children: [
                    {
                        path: basePath,
                        element: <PerksSniptechDashboard/>,
                    },
                ],
            },

            // property
            {
                path: routes.property.base,
                element: <SidebarLayout/>,
                children: [
                    {
                        path: basePath,
                        element: <HomeProperty/>,
                    },
                    {
                        path: routes.property.create,
                        element: <AddProperty/>,
                    },
                    {
                        path: routes.property.edit,
                        element: <EditProperty/>,
                    },
                ],
            },

            // contracts
            {
                path: routes.contracts.base,
                element: <SidebarLayout/>,
                children: [
                    {
                        path: basePath,
                        element: <ContractsHome/>,
                    },
                    {
                        path: routes.contracts.property,
                        element: <PropertyContracts/>,
                    },
                    {
                        path: routes.contracts.create,
                        element: <CreateContract/>,
                    },
                    {
                        path: routes.contracts.details,
                        element: <ContractDetails/>,
                    },
                ],
            },

            // landlord profile
            {
                path: routes.landlord.base,
                element: <SidebarLayout/>,
                children: [
                    {
                        path: basePath,
                        element: <LandLordProfile/>,
                    },
                    {
                        path: routes.landlord.bankInfo,
                        element: <LandlordBankInformation/>,
                    },
                    {
                        path: routes.landlord.signature,
                        element: <LandlordSignature/>,
                    },
                ],
            },

            // connecto to bank
            {
                path: routes.connectBank,
                element: <SidebarLayout/>,
                children: [
                    {
                        path: basePath,
                        element: <ConnectBank/>,
                    },
                ],
            },

            // taxes
            {
                path: routes.setupTaxes,
                element: <SidebarLayout/>,
                children: [
                    {
                        path: basePath,
                        element: <SetupTaxes/>,
                    },
                ],
            },

            //     loans
            {
                path: routes.loans.base,
                element: <SidebarLayout/>,
                children: [
                    {
                        path: basePath,
                        element: <Loans/>,
                    },
                    {
                        path: routes.loans.connectBank,
                        element: <ConnectBankForLoans/>,
                    },
                    {
                        path: routes.loans.congratulations,
                        element: <Congratulations/>,
                    },
                    {
                        path: routes.loans.newLoanForm,
                        element: <NewLoanForm/>
                    }
                ],
            },

            // reports
            {
                path: routes.reports.base,
                element: <SidebarLayout/>,
                children: [
                    // {
                    //     path: basePath,
                    //     element: <HomeReports/>,
                    // },
                    {
                        path: `${routes.reports.property}/:propertyId`,
                        element: <PropertyReport/>
                    },
                    {
                        path: routes.reports.userProperties,
                        element: <UserSearchedProperties/>
                    },
                    {
                        path: routes.reports.allProperties,
                        element: <AllProperties/>
                    },
                    {
                        path: routes.reports.analytics,
                        element: <ReportsAnalytics/>
                    }
                ],
            },
        ],
    },

    // guest portal
    {
        path: `${routes.contracts.base}/${routes.contracts.guestPortal}`,
        element: <BaseLayout/>,
        children: [
            {
                path: ":contractId",
                element: <GuestPortal/>,
            },
        ],
    },

    // not found page
    {
        path: "*",
        element: <Status404/>,
    },
];

export default routesComponent;
