import {Box, Container, Typography} from "@mui/material";

import LanguageSelector from "../../components/languageSelector";
import logo from "../../static/logo.png";

type Props = {
    children: JSX.Element;
};

const AuthLayout = (props: Props) => {
    const {children} = props;
    // const isLargeScreen = useMediaQuery("(min-width: 1280px)");

    return (
        <>
            <Container
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // marginBottom: 0,
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: 'space-between',
                        width: "100%",
                        marginTop: 50
                    }}
                >
                    {/*<img src={logo} alt="Logo" width={150}/>*/}
                    <Box
                        sx={{
                            marginLeft: 4,
                            backgroundColor: '#5c48f7',
                            width: '120px',
                            padding: 0.5,
                            borderRadius: 0.5,
                            textAlign: 'center',
                        }}
                    >
                        <Typography style={{fontWeight: 'bold', color: 'white'}}>
                            Nidoscout.com
                        </Typography>
                    </Box>
                    {/*<LanguageSelector/>*/}
                </div>
            </Container>
            <Container maxWidth="sm">{children}</Container>
        </>
    );
};

export default AuthLayout;
